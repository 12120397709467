import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Bounce from 'react-reveal/Bounce'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Pagination from '../components/Pagination'

// import Carousel from '../components/Carousel'
class Blog extends React.Component {
  render() {
    const { data, pageContext, intl } = this.props
    const postsFilteredByLang = intl.locale == 'pl' ? data.pl : data.en
    const { edges: posts } = postsFilteredByLang

    return (
      <Layout>
        <section className="section-bubble--blogPage">
          <Bounce left>
            <PostList posts={posts} title={intl.messages.news} />
            <Pagination pageContext={pageContext} pathPrefix="/" />
          </Bounce>
        </section>
      </Layout>
    )
  }
}

Blog.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export default injectIntl(Blog)

export const pageQuery = graphql`
  query NewsQuery($limit: Int!, $skip: Int!) {
    pl: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { language: { language: { eq: "pl" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }

    en: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { language: { language: { eq: "en" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
